/* Dropdown.css */
.dropdown-container {
  position: relative;
}

.dropdown-header {
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown-list-container {
  position: absolute;
  width: 100%;
  border: 1px solid #ccc;
  z-index: 1000;
  background: white;
}

.dropdown-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdown-list-item {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-list-item:hover {
  background-color: #f2f2f2;
}

.dropdown-list-item i {
  margin-right: 10px;
}
