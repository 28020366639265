
//
// Form-Upload
//

/* Dropzone */
.dropzone {
  min-height: 130px;
  border: 2px dashed #cfcfcf;
  background: var(--#{$prefix}custom-white);
  border-radius: 6px;

  .dz-message {
    font-size: 24px;
    width: 100%;
  }
}